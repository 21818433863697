const validations = {
  hasValue(val) {
    if (!!val == false || !!val.toString().trim() == false) {
      return false;
    }

    return true;
  },

  SearchPermissionHandling(data){
    console.log(data)
     data = true
    // alert('SP')
  }
};

export default validations;
