import listAPI from "@/api/listAPI.js";
import alerts from "../common/notifications";
import patientAPI from "@/api/patientAPI.js";
import reportsAPI from "@/api/reportsAPI.js";
import Header from "@/components/Header.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import permissions from "@/api/permissions.js";
import validations from "@/validations/index";

export default {
  name: "patientsearch",
  components: {
    Header,
    alerts,
  },
  data: () => ({
    formData: {
      first_name: null,
      last_name: null,
      city: null,
      state_id: null,
      phone: null,
      email: null,
      doctor_id: null,
      diagnosis_id: null,
      remote_services_id: null,
      patient_status_id: null,
    },

    errorType: "",
    message: "",
    snackbar: false,
    loading: false,

    autoEnrollPopup: false,
    happyFoxNewEnrolmentItems: [],
    enrollType: [], // ["New", "RE-Enrollment", "GEN CHG", "Duplicate", "Transfer"],
    autoEnrolment: {},
    NewEnrolmentItemsLoading: false,
    menu2: null,

    //Doctor-------------
    model_doctor: null,
    isLoadingDoctor: false,
    searchDoctor: null,

    //Diagnosis-----------
    model_diagnosis: null,
    isLoadingDiagnosis: false,
    searchDiagnosis: null,

    //Device Mpodel-------
    vrPatientDevice: {},
    vrDeviceModel: null,
    isLoadingModel: false,
    vrKeyWordDeviceModel: null,

    //Carrier Name--------
    vrInsurancePrimary: {},
    vrCarrierPrimary: null,
    isLoadingCarrierPrimary: false,
    vrKeyWordCarrierPrimary: null,

    vrState: [],
    vrStatus: [],
    vrRemoteService: [],
    vrDoctors: [],
    vrDiagnosis: [],

    duplicatePatientPopup: false,
    vrDuplicatePatients: [],
    vrExistingPatientId: null,
    duplicatePatientHeaders: [
      { sortable: false, text: "", value: "id", width: "5%" },
      {
        sortable: false,
        text: "PATIENT ID",
        value: "patient_code",
        width: "4%",
      },
      { sortable: false, text: "AFFIL ID", value: "affiliate_id", width: "4%" },

      { sortable: false, text: "NAME", value: "full_name", width: "6%" },
      { sortable: false, text: "DOB", value: "birth_date", width: "4%" },
      {
        sortable: false,
        text: "ADDRESS",
        value: "address1",
        width: "9%",
      },
      { sortable: false, text: "CITY", value: "city", width: "5%" },
      { sortable: false, text: "STATE", value: "state_code", width: "4%" },
      { sortable: false, text: "ZIP CODE", value: "zipcode", width: "5%" },
      { sortable: false, text: "Phone", value: "phone_num1", width: "5%" },
      { sortable: false, text: "Email", value: "e_mail", width: "5%" },
    ],

    headers: [
      { sortable: false, text: "STATUS", value: "patient_status", width: "5%" },
      {
        sortable: false,
        text: "PATIENT ID",
        value: "patient_code",
        width: "4%",
      },
      { sortable: false, text: "AFFIL ID", value: "affiliate_id", width: "4%" },

      { sortable: false, text: "NAME", value: "full_name", width: "6%" },
      { sortable: false, text: "DOB", value: "birth_date", width: "4%" },
      {
        sortable: false,
        text: "ADDRESS",
        value: "patient_address",
        width: "9%",
      },
      { sortable: false, text: "CITY", value: "city", width: "5%" },
      { sortable: false, text: "STATE", value: "state_code", width: "4%" },
      { sortable: false, text: "ZIPCODE", value: "zip_code", width: "4%" },
      { sortable: false, text: "PHONE", value: "phone", width: "10%" },
      { sortable: false, text: "EMAIL", value: "email", width: "8%" },
      {
        sortable: false,
        text: "REMOTE SERVICES",
        value: "remote_services",
        width: "10%",
      },
      { sortable: false, text: "DOCTOR", value: "doctor", width: "9%" },
      { sortable: false, text: "DIAGNOSIS", value: "diagnosis", width: "9%" },
    ],

    totalRecords: 0,
    tblLoading: false,
    options: {},
    vrPatients: [],

    exportLoadingLabel: false,
    exportLoadingExcel: false,
    exportLoadingCSV: false,
    exportLoadingPDF: false,

    disableExport: false,

    isPostback: false,

    reportsURL: {
      label: "exportDocuments/exportToLabel",
      csv: "exportDocuments/exportToCsv",
      excel: "exportDocuments/exportToExcel",
      pdf: "exportDocuments/exportToPdf",
    },

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }),
  mounted() {
    store.state.patientId = null;
    store.state.patientInfo.first_name = null;
    store.state.patientInfo.last_name = null;
  },
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },

  methods: {
    addPatient() {
      store.state.patientId = null;
      store.state.patientInfo.first_name = null;
      store.state.patientInfo.last_name = null;
      store.state.redirectURL = "search";

      router.push("patient");
    },
    loadPatients() {
      if (!this.isPostback) {
        this.isPostback = true;
        this.disableExport = true;
        this.vrPatients = [];
        this.totalRecords = 0;
        return false;
      }
      this.tblLoading = true;
      this.formData.pagination = this.options;
      this.formData.exportType = null;

      patientAPI
        .getPatientSearchResults(this.formData)
        .then((response) => {
          //Show message if status = false
          if (!response.data.status) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }

          if (response.data.results !== null) {
            this.vrPatients = response.data.results.Patients;
            this.totalRecords = response.data.results.PatientCount.total_count;
            if (this.totalRecords > 0) {
              this.disableExport = false;
            } else {
              this.disableExport = true;
            }
          } else {
            this.vrPatients = [];
            this.totalRecords = 0;
            this.disableExport = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.disableExport = true;
        })
        .finally(() => {
          this.tblLoading = false;
        });
    },
    searchPatients: function (e) {
      e.preventDefault();

      this.options.page = 1;

      //This method is used to load data
      this.loadPatients();
    },
    selectPatient(item) {
      //Set the patient Id for other screens/flow
      store.state.patientId = item.patient_id;

      store.state.redirectURL = "search";
      router.push("patient");
    },

    exportLabel() {
      this.formData.exportType = "LABEL";
      this.exportLoadingLabel = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToLabel",
          this.reportsURL.label,
          this.formData,
          "Patient-Details-Label.xlsx",
          this.exportLoadingLabel
        )
        .then(() => {
          this.exportLoadingLabel = false;
        });
    },
    exportCSV() {
      this.formData.exportType = "CSV";
      this.exportLoadingCSV = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToCsv",
          this.reportsURL.csv,
          this.formData,
          "Patient-Details.csv"
        )
        .then(() => {
          this.exportLoadingCSV = false;
        });
    },
    exportExcel() {
      this.formData.exportType = "EXCEL";
      this.exportLoadingExcel = true;
      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToExcel",
          this.reportsURL.excel,
          this.formData,
          "Patient-Details.xlsx"
        )
        .then(() => {
          this.exportLoadingExcel = false;
        });
    },
    exportPDF() {
      this.formData.exportType = "PDF";
      this.exportLoadingPDF = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToPdf",
          this.reportsURL.pdf,
          this.formData,
          "Patient-Details.pdf"
        )
        .then(() => {
          this.exportLoadingPDF = false;
        });
    },

    //-------------------------------------------------------
    //Device Models
    //-------------------------------------------------------
    searchDeviceModel(val) {
      //"vrDeviceModel", "isLoadingCarrierPrimary"

      // Items have already been requested
      if (this.isLoadingCarrierPrimary) return; //Return

      if (val === null) return; //Return

      this.isLoadingCarrierPrimary = true; //initiate loading

      listAPI
        .getDeviceModelList({ SearchString: val })
        .then((response) => {
          if (response.data.results !== null) {
            this.vrDeviceModel = response.data.results.Devices;
          } else {
            this.vrDeviceModel = [];
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingCarrierPrimary = false;
        });

      this.isLoadingCarrierPrimary = false;
    }, //End searchDiagnosis
    //-------------------------------------------------------

    //-------------------------------------------------------
    //Search Insurer
    //-------------------------------------------------------
    searchInsurer(val) {
      // Items have already been requested
      if (this.isLoadingCarrierPrimary) return; //Return

      if (val === null) return; //Return

      this.isLoadingCarrierPrimary = true; //initiate loading

      listAPI
        .getInsuranceList({ SearchString: val })
        .then((response) => {
          if (response.data.results !== null)
            this.vrCarrierPrimary = response.data.results.InsuranceCarrier;
          else this.vrCarrierPrimary = [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingCarrierPrimary = false;
        });

      this.isLoadingCarrierPrimary = false;
    },
    //-------------------------------------------------------

    clearAllFields() {
      this.formData = {};

      this.options.page = 1;
      this.isPostback = false;
      this.loadPatients();

      this.autoEnrolment = {};
    },

    showAutoEnrolPopUp() {
      //Clear fields
      this.NewEnrolmentItemsLoading = true;
      this.autoEnrolment = {};
      this.happyFoxNewEnrolmentItems = [];

      //Load Happy Fox enrolment list
      patientAPI
        .getEnrolmentList({ id: null })
        .then((response) => {
          this.NewEnrolmentItemsLoading = false;
          this.happyFoxNewEnrolmentItems = response.data.results;
        })
        .catch((error) => {
          console.log(error);
        });

      listAPI
        .getLookup("ENROLLMENT_TYPE")
        .then((response) => {
          this.enrollType = response.data.results.Lookup;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveHappyFoxEnquiry(vrIgnore_Duplicate_Check) {
      //----------------------------------------------
      //Required field validations
      let isValid = true;
      // this.autoEnrolment.ignore_duplicate_check = vrIgnore_Duplicate_Check;
      this.autoEnrolment.ignore_duplicate_check = null;
      this.autoEnrolment.existing_patient_id = null

      if (vrIgnore_Duplicate_Check == "N") {
        this.autoEnrolment.ignore_duplicate_check = "LinkSelected";
        this.autoEnrolment.existing_patient_id = this.vrExistingPatientId;
      }

      if (vrIgnore_Duplicate_Check == "Y") {
        this.autoEnrolment.ignore_duplicate_check = "AddNew";
        this.autoEnrolment.existing_patient_id = null;
      }

      if (
        isValid &&
        (!validations.hasValue(this.autoEnrolment.id) ||
          !validations.hasValue(this.autoEnrolment.affiliate_id) ||
          !validations.hasValue(this.autoEnrolment.enrollmentType) ||
          !validations.hasValue(this.autoEnrolment.first_message))
      ) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return;
      }

      //----------------------------------------------
      patientAPI
        .getSaveEnrollmentHappyFox(this.autoEnrolment)
        .then((response) => {
          if (response.data !== null) {
            //Show notification to user
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              //console.log(response.data.results.id);
              this.duplicatePatientPopup = false;
              this.autoEnrollPopup = false;
              this.autoEnrolment = {};
              store.state.patientId = response.data.results.id;
              router.push("patient");
            }

            //case for duplicate patient
            if (
              response.data.results != null &&
              response.data.results.confirm != null
            ) {
              if (response.data.results.confirm == "Y") {
                this.duplicatePatientPopup = true;
                this.vrDuplicatePatients =
                  response.data.results.duplicate_patient;
                // if (confirm(response.data.message)) {
                //   this.autoEnrolment.ignore_duplicate_check = "Y";
                //   this.saveHappyFoxEnquiry();
                // }
                this.vrNotify.visible = false;
                return false;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.loadPatients();
      },
      deep: true,
    },
    //Primary
    vrKeyWordCarrierPrimary(val) {
      if (val == null) {
        this.vrInsurancePrimary = {};
        this.vrCarrierPrimary = [];
        return;
      }
      this.searchInsurer(val);
    },
    searchDoctor(val) {
      // Items have already been requested
      if (this.isLoadingDoctor) return; //Return

      this.isLoadingDoctor = true; //initiate loading

      if (val !== null) {
        listAPI
          .getDoctorsList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this.vrDoctors = response.data.results.Doctors;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDoctor = false;
          });
      }

      this.isLoadingDoctor = false;
    },
    searchDiagnosis(val) {
      // Items have already been requested
      if (this.isLoadingDiagnosis) return; //Return

      this.isLoadingDiagnosis = true; //initiate loading

      if (val !== null) {
        listAPI
          .getDiagnosisList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this.vrDiagnosis = response.data.results.Diagnosis;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDiagnosis = false;
          });
      }

      this.isLoadingDiagnosis = false;
    },
    vrKeyWordDeviceModel(val) {
      if (val == null) {
        this.vrInsurancePrimary = {};
        return;
      }
      this.searchDeviceModel(val);
    },
  },
  async beforeMount() {
    await Promise.all([
      listAPI
        .getPatientSearchMasters()
        .then((response) => {
          this.vrState = response.data.results.States;
          this.vrStatus = response.data.results.Status;
          this.vrRemoteService = response.data.results.RemoteServices;
        })
        .catch((error) => {
          console.log(error);
        }),
    ]);
  },
  created() {
    //Case when user is not logged in
    // if (store.getters.user == null) {
    //   this.$router.push("login");
    //   return;
    //   //window.location.href = "login";
    // }
    //console.log(permissions.getPermissions("search"));
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
